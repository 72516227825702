@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  background: #eeeeee;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 14px;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    background-color: #121212;
    color: #FFFF;
  }
}